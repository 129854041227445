export default function ChatIcon({ alt,status,}) {
  // return <img src={image} alt={alt} className="w-12" />;
  let imageSrc, chatIconAlt;

  if (status === 'sender') {
    imageSrc = "/icons/sender-icon.png";
    chatIconAlt = "sender Icon";
  } 
  else {
    imageSrc = "/icons/bot-icon.png";
    chatIconAlt = "bot Icon";
  }

  return (
    
    <div key={status} >
    
    <img src={imageSrc} alt={chatIconAlt} className="w-12 h-12 object-cover rounded-full"  />
    </div>
    
  );

}
