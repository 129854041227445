import React from "react";
import ChatIcon from "../atoms/chatIcon";

export default function ChatTile({ status, message }) {
  let divClass;
  let chatIconImage;
  let chatIconAlt;

  // if (status === "sender") {
  //   divClass = `flex flex-row-reverse items-center w-full`;
  // } else {
  //   divClass = `flex flex-row items-center w-full fit-content`;
  // }
  if (status === "sender") {
    divClass = `flex flex-row-reverse items-end w-full py-3`;
    //chatIconImage = "/icons/sender-icon.png"; 
    chatIconAlt = "sender Icon"; 
  } else {
    divClass = `flex flex-row items-end w-full fit-content py-3`;
    // chatIconImage = "/icons/bot-icon.png"; 
    chatIconAlt = "bot Icon"; 
  }

  return (
    <div className={divClass}>
      {/* <ChatIcon image={"/icons/user-icon.png"} alt={"User Icon"} /> */}
      <ChatIcon status={status} alt={chatIconAlt} />
      <div className={`max-w-[500px] min-h-12 rounded-3xl bg-primary-light shadow-grey shadow-md dark:bg-secondary-dark dark:shadow-none mx-7 px-4 py-2 text-start text-white`} >
        {message}
      </div>
    </div>
  );
}
