export default function PrimaryButton({ title, fontSize, shadow, onClick }) {

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <button
      className={`py-5 bg-blue px-12 shadow-md ${shadow} text-primary-light rounded-3xl cursor-pointer font-Arial  text-center dark:bg-secondary-dark dark:text-white dark:shadow-none`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}