import React, { useState } from "react";
import { Icon } from "@iconify/react";
import darkMode from "@iconify/icons-gg/dark-mode";
import flagForIndonesia from "@iconify/icons-emojione/flag-for-indonesia";
import logoutIcon from "@iconify/icons-material-symbols/logout";

export default function UserIcon({ image, alt }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  function toggleDarkmode() {
    setDarkMode(prevDarkMode => !prevDarkMode)
}

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={toggleDropdown}
          type="button"
          className="flex items-center focus:outline-none"
        >
          <img src="/icons/sender-icon.png" alt={alt} className="w-14 h-14 object-cover rounded-full" />
        </button>
      </div>
      
    </div>
  );
}
