import PrimaryButton from "../atoms/primaryButton";

export default function HomeSuggestions({ onSuggestedQuestionClick }) {
  const q1 = "Jelaskan proses Validate Demand Plan List";
  const q2 = "Berapa Plafon rawat gigi?";
  const q3 = "Apa yang meliputi rawat inap?";
  const q4 = "How much is the dental care coverage limit?";

  const handleButtonClick = (question) => {
    onSuggestedQuestionClick(question);
  };

  return (
    <div className="w-full flex flex-row gap-3 font-Arial justify-evenly">
      <PrimaryButton
        title={q1}
        fontSize={"text-sm"}
        shadow={"shadow-grey"}
        bg={"bg-blue-500"}
        onClick={() => handleButtonClick(q1)}
      />
      <PrimaryButton
        title={q2}
        fontSize={"text-sm"}
        shadow={"shadow-grey"}
        onClick={() => handleButtonClick(q2)}
      />
      <PrimaryButton
        title={q3}
        fontSize={"text-sm"}
        shadow={"shadow-grey"}
        onClick={() => handleButtonClick(q3)}
      />
      <PrimaryButton
        title={q4}
        fontSize={"text-sm"}
        shadow={"shadow-grey"}
        onClick={() => handleButtonClick(q4)}
      />
    </div>
  );
}