import UserIcon from "../atoms/userIcon";

export default function HeaderHome() {
  return (
    <div className="flex flex-row justify-end">
      <div className=" text-center text-2xl font-semibold mt-2 mr-4 font-Arial">
        GenAI-Powered Chatbot
      </div>
      <UserIcon image={"icons/user-icon.png"} alt={"User Icon"} />
    </div>
  );
}
